// src/App.tsx
import React, { useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";

const App: React.FC = () => {
  const [selectedChain, setSelectedChain] = useState("apechain");

  // Embed URLs for each chain
  const embeds = {
    ethereum:
      "https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0x6Fa9FA1CBC7dc1E9CDf1452C8dA2d4b9953e9979&chain=%7B%22name%22%3A%22Ethereum+Mainnet%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F1.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22eth%22%2C%22chainId%22%3A1%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22ethereum%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmdwQDr6vmBtXmK2TmknkEuZNoaDqTasFdZdu3DRw8b2wt%22%2C%22width%22%3A1000%2C%22height%22%3A1628%2C%22format%22%3A%22png%22%7D%7D&clientId=1fc1140d1f2b9f4a31d5814e855587d8&theme=dark&primaryColor=purple",
    apechain:
      "https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0x98eFb7D0d93a73A17dB61ba130cf913C4fEf238c&chain=%7B%22name%22%3A%22ApeChain%22%2C%22chain%22%3A%22%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F33139.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22ApeCoin%22%2C%22symbol%22%3A%22APE%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22APE%22%2C%22chainId%22%3A33139%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22apechain%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcyHSQnd747pd7EmtobQd12vh4vvyYYvnNNQqKrG12ky1%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22svg%22%7D%7D&clientId=1fc1140d1f2b9f4a31d5814e855587d8&theme=dark&primaryColor=purple",
  };

return (
    <div className="App">
      <Header />
      <main style={styles.main}>
        
        <div style={styles.centerContainer}>
          <div style={styles.tabContainer}>
          <h2 style={styles.title}>Mint Your Anonimal</h2>
            <button
              style={selectedChain === "ethereum" ? styles.activeTab : styles.tab}
              onClick={() => setSelectedChain("ethereum")}
            >
              Ethereum
            </button>
            <button
              style={selectedChain === "apechain" ? styles.activeTab : styles.tab}
              onClick={() => setSelectedChain("apechain")}
            >
              ApeChain
            </button>
          </div>

          <div className="iframe-container" style={styles.embedContainer}>
            <iframe
              src={embeds[selectedChain as "ethereum" | "apechain"]}
              width="100%"
              height="600px"
              style={{ border: "none" }}
              title={`Anonimals Minting on ${selectedChain.charAt(0).toUpperCase() + selectedChain.slice(1)}`}
            ></iframe>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

const styles = {
  main: {
    backgroundColor: "#5e1a8b",
    minHeight: "70vh",
    padding: "20px",
    textAlign: "center" as const,
  },
  title: {
    color: "#ffffff",
    fontSize: "2em",
    fontWeight: "bold",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    marginBottom: "20px",
  },
  centerContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  tabContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  tab: {
    padding: "12px 24px",
    cursor: "pointer",
    backgroundColor: "#f1f1f1",
    border: "2px solid #ccc",
    borderRadius: "8px",
    margin: "0 10px",
    fontWeight: "bold",
    color: "#333",
    transition: "background-color 0.3s, color 0.3s",
  },
  activeTab: {
    padding: "12px 24px",
    cursor: "pointer",
    backgroundColor: "#3b82f6",
    color: "#ffffff",
    border: "2px solid #3b82f6",
    borderRadius: "8px",
    margin: "0 10px",
    fontWeight: "bold",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.3s, color 0.3s",
  },
  embedContainer: {
    marginTop: "20px",
    width: "100%", // Ensures the iframe takes full width of the center container
    maxWidth: "800px", // Limit the width for better visual appearance
  },
};

export default App;