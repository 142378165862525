import React, { CSSProperties } from "react";

const Header: React.FC = () => {
  return (
    <header style={styles.header}>
       <div style={styles.content}>
      <h1 style={styles.title}>Anonimals</h1>
      <p style={styles.description}>
          The first anonymous animal PFP project on Ethereum, is now also on <b>ApeChain!</b> Join the ranks of Anonimals
          holders and forever be an amazing person as part of the{" "}
          <span style={styles.underline}>Anonymous Adjective Animal Elite</span>.
        </p></div><div>
      <a href="https://twitter.com/anonimals_nft" target="_blank" rel="noopener noreferrer" style={styles.link}>
        Follow us on Twitter
      </a> | <a href="https://magiceden.us/collections/apechain/0x98efb7d0d93a73a17db61ba130cf913c4fef238c" target="_blank" rel="noopener noreferrer" style={styles.link}>MagicEden</a> |  <a href="https://opensea.io/collection/anonimalsnft" target="_blank" rel="noopener noreferrer" style={styles.link}>OpenSea</a>
      </div>
    </header>
  );
};

const styles: { header: CSSProperties; content: CSSProperties; title: CSSProperties; description: CSSProperties; link: CSSProperties; underline: CSSProperties } = {
  header: {
    textAlign: "center",
    padding: "20px",
    background: "linear-gradient(45deg, #ff8a00, #e52e71)",
    color: "white",
  },
  content: {
    maxWidth: "700px",
    margin: "0 auto",
  },
  title: {
    fontSize: "2.5em",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  description: {
    fontSize: "1.25em",
    lineHeight: "1.5",
    marginBottom: "20px",
  },
  link: {
    fontSize: "1.1em",
    color: "#FFFFFF",
    backgroundColor: "#1DA1F2",
    padding: "10px 15px",
    textDecoration: "none",
    borderRadius: "5px",
    fontWeight: "bold",
    transition: "background-color 0.3s",
  },
  underline: {
    textDecoration: "underline",
    fontWeight: "bold",
  },
};

export default Header;
